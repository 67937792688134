<template>
  <v-dialog v-model="dialog" max-width="600px" no-click-animation persistent>
    <v-card>
      <v-card-text class="pt-5">
        <v-form ref="form">
          <v-row>
            <v-col cols="12" md="12">
              <v-alert outlined type="info" text>{{ tripRequestConfig.messages.acceptance }}</v-alert>
            </v-col>
          </v-row>
          <v-row class="align-center">
            <v-col cols="12" md="9">
              <v-label>I have read and understand the information above</v-label>
            </v-col>
            <v-col cols="12" md="3">
              <div class="d-flex align-center justify-center">
                <span>No</span>
                <v-switch
                  class="px-2"
                  v-model.number="tripRequest.userAccepted"
                  :true-value="1"
                  :false-value="0"
                  inset
                ></v-switch>
                <span>Yes</span>
              </div>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="closeDialog"> Cancel </v-btn>
        <v-btn
          color="primary"
          text
          :loading="loading"
          :disabled="!tripRequest.userAccepted || loading"
          @click="submit()"
        >
          Submit
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapMutations } from 'vuex';
import { SUBMIT_TRIP_REQUEST, GET_TRIP_REQUESTS, GET_TRIP_REQUEST } from '@/store/modules/TripRequest/actions';

export default {
  name: 'TripAcceptance',
  inject: ['eventHub'],
  components: {},
  props: {
    tripRequest: Object,
    tripRequestConfig: Object,
  },
  data() {
    return {
      dialog: false,
      data: {},
      loading: false,
      // redirectToDashboard: false,
    };
  },
  created() {
    // if (this.$router.history.current.query.dashboard) this.redirectToDashboard = true;
  },
  methods: {
    ...mapActions('tripRequest', [SUBMIT_TRIP_REQUEST, GET_TRIP_REQUESTS, GET_TRIP_REQUEST]),
    ...mapMutations('tripRequest', ['updateTripRequest']),
    async submit() {
      this.$emit('submit', true);
      this.loading = true;
      var valid = this.$refs.form.validate();
      if (valid) {
        await this.submitTripRequest(this.tripRequest.id);
        const updated = await this.getTripRequest(this.tripRequest.id);
        this.updateTripRequest(updated.tripRequest);
        this.dialog = false;
        this.loading = false;

        const yes = await this.$myalert({
          message: `Trip Request #${this.tripRequest.id} Submitted`,
          subMessage: 'Redirecting to trip list - click the button to view trip details and/or create a duplicate trip',
          type: 'success',
          buttonLabel: 'Go To Trip',
        });

        if (yes) {
          this.$router.push(`/trip-request/${this.tripRequest.id}`).catch((err) => {
            if (err.name === 'NavigationDuplicated') this.$emit('refreshTripRequest');
          });
        } else this.$router.push('/trip-requests');
      }
      this.dialog = false;
      this.loading = false;
    },
    closeDialog() {
      this.dialog = false;
      this.$emit('submit', false);
    },
  },
};
</script>

<style lang="scss"></style>
